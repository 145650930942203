<template>
  <div>
    <div class="worksite ver5">
      <DateSelector
          @search="getList"
          :title="$t('top-select-site-statistics-direction')"
      />
      <div class="box one">
        <select
            class="right"
            v-model="chartType"
            @change="selectChart(chartType)"
        >
          <option selected value hidden>{{ $t("site-statistics-data-graph-type") }}</option>
          <option value="bar">{{ $t("site-statistics-data-graph-bar") }}</option>
          <option value="pie">{{ $t("site-statistics-data-graph-pie") }}</option>
        </select>
        <BarChartStack
            v-if="chartType == 'bar'"
            :chartDate="chartDate"
            :refData="refData"
        />
        <div class="pieChart" v-if="chartType == 'pie'">
          <PieChart :chartData="preData" />
          <PieChart :chartData="refTotalData" :labels="region" />
        </div>
      </div>
      <div class="box one statistics2-box">
        <h2 class="bold">{{ $t("site-statistics-searching-period") }}</h2>
        <div class="check-table">
          <table>
            <tr>
              <th>{{ $t("site-statistics-column-date") }}</th> <!--title-->
              <th>{{ $t("site-statistics-legend-seoul") }}</th>
              <th>{{ $t("site-statistics-legend-busan") }}</th>
              <th>{{ $t("site-statistics-legend-daegu") }}</th>
              <th>{{ $t("site-statistics-legend-incheon") }}</th>
              <th>{{ $t("site-statistics-legend-gwangju") }}</th>
              <th>{{ $t("site-statistics-legend-daejeon") }}</th>
              <th>{{ $t("site-statistics-legend-ulsan") }}</th>
              <th>{{ $t("site-statistics-legend-sejong") }}</th>
              <th>{{ $t("site-statistics-legend-gyeonggi") }}</th>
              <th>{{ $t("site-statistics-legend-gangwon") }}</th>
              <th>{{ $t("site-statistics-legend-chungbuk") }}</th>
              <th>{{ $t("site-statistics-legend-chungnam") }}</th>
              <th>{{ $t("site-statistics-legend-jeollabuk") }}</th>
              <th>{{ $t("site-statistics-legend-jeollanam") }}</th>
              <th>{{ $t("site-statistics-legend-gyeongbuk") }}</th>
              <th>{{ $t("site-statistics-legend-gyeongnam") }}</th>
              <th>{{ $t("site-statistics-legend-jeju") }}</th>
            </tr>
            <tr class="check-total">
              <td>{{ $t("site-statistics-column-total") }}</td>
              <td>{{ refSeoulToTal }}</td>
              <td>{{ refBusanToTal }}</td>
              <td>{{ refDaeguToTal }}</td>
              <td>{{ refIncheonToTal }}</td>
              <td>{{ refGwangjuToTal }}</td>
              <td>{{ refDaejeonToTal }}</td>
              <td>{{ refUlsanToTal }}</td>
              <td>{{ refSejongToTal }}</td>
              <td>{{ refGyeonggiToTal }}</td>
              <td>{{ refGangwonToTal }}</td>
              <td>{{ refChungbukToTal }}</td>
              <td>{{ refChungnamToTal }}</td>
              <td>{{ refJeonbukToTal }}</td>
              <td>{{ refJeonnamToTal }}</td>
              <td>{{ refGyeongbukToTal }}</td>
              <td>{{ refGyeongnamToTal }}</td>
              <td>{{ refJejuToTal }}</td>
            </tr>
            <tr v-for="(item, i) in refLiveData" :key="i">
              <td>{{ item.date }}</td>
              <td>{{ item.seoul }}</td>
              <td>{{ item.busan }}</td>
              <td>{{ item.daegu }}</td>
              <td>{{ item.incheon }}</td>
              <td>{{ item.gwangju }}</td>
              <td>{{ item.daejeon }}</td>
              <td>{{ item.ulsan }}</td>
              <td>{{ item.sejongSi }}</td>
              <td>{{ item.gyeonggiDo }}</td>
              <td>{{ item.gangwonDo }}</td>
              <td>{{ item.chungcheongbukDo }}</td>
              <td>{{ item.chungcheongnamDo }}</td>
              <td>{{ item.jeollabukDo }}</td>
              <td>{{ item.jeollanamDo }}</td>
              <td>{{ item.gyeongsangbukDo }}</td>
              <td>{{ item.gyeongsangnamDo }}</td>
              <td>{{ item.jejuDo }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.statistics2-box{padding-bottom: 10px !important;}
.check-total{position: sticky;top: 31px;}
.check-total > td{background: #e9f5ff !important;}
</style>
<script>
import BarChartStack from "@/components/charts/BarChartStack";
import PieChart from "@/components/charts/PieChart";

import moment from "moment";
import DateSelector from "@/components/statistics/DateSelector";
import { fetchAddrList } from "@/api/statistics";
import i18n from "@/lang";

export default {
  components: { DateSelector, BarChartStack, PieChart },
  name: "Worksite5",
  data() {
    return {
      moment: moment,
      startYmd: "",
      endYmd: "",
      showDatepicker: false,
      chartDate: [],
      refData: [],
      refLiveData: [],
      startDate: "",
      endDate: "",
      current: 0,
      ration: 0,
      refBusanToTal: "",
      refChungbukToTal: "",
      refChungnamToTal: "",
      refDaeguToTal: "",
      refDaejeonToTal: "",
      refGangwonToTal: "",
      refGwangjuToTal: "",
      refGyeonggiToTal: "",
      refGyeongbukToTal: "",
      refGyeongnamToTal: "",
      refIncheonToTal: "",
      refJejuToTal: "",
      refJeonbukToTal: "",
      refJeonnamToTal: "",
      refSejongToTal: "",
      refUlsanToTal: "",
      refSeoulToTal: "",
      seoul: [],
      busan: [],
      daegu: [],
      incheon: [],
      gwangju: [],
      daejeon: [],
      ulsan: [],
      sejongSi: [],
      gyeonggiDo: [],
      gangwonDo: [],
      chungcheongbukDo: [],
      chungcheongnamDo: [],
      jeollabukDo: [],
      jeollanamDo: [],
      gyeongsangbukDo: [],
      gyeongsangnamDo: [],
      jejuDo: [],

      refTotalData: [],
      preData: [],

      preBusanToTal: "",
      preChungbukToTal: "",
      preChungnamToTal: "",
      preDaeguToTal: "",
      preDaejeonToTal: "",
      preGangwonToTal: "",
      preGwangjuToTal: "",
      preGyeonggiToTal: "",
      preGyeongbukToTal: "",
      preGyeongnamToTal: "",
      preIncheonToTal: "",
      preJejuToTal: "",
      preJeonbukToTal: "",
      preJeonnamToTal: "",
      preSejongToTal: "",
      preUlsanToTal: "",
      preSeoulToTal: "",

      region: [
        i18n.t("site-statistics-legend-seoul"),
        i18n.t("site-statistics-legend-busan"),
        i18n.t("site-statistics-legend-daegu"),
        i18n.t("site-statistics-legend-incheon"),
        i18n.t("site-statistics-legend-gwangju"),
        i18n.t("site-statistics-legend-daejeon"),
        i18n.t("site-statistics-legend-ulsan"),
        i18n.t("site-statistics-legend-sejong"),
        i18n.t("site-statistics-legend-gyeonggi"),
        i18n.t("site-statistics-legend-gangwon"),
        i18n.t("site-statistics-legend-chungbuk"),
        i18n.t("site-statistics-legend-chungnam"),
        i18n.t("site-statistics-legend-jeollabuk"),
        i18n.t("site-statistics-legend-jeollanam"),
        i18n.t("site-statistics-legend-gyeongbuk"),
        i18n.t("site-statistics-legend-gyeongnam"),
        i18n.t("site-statistics-legend-jeju"),
      ],
      chartType: "bar",
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 3, sub: 2 });
  },
  methods: {
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    handleSelect(val) {
      if (val == "") {
        this.showDatepicker = true;
      } else {
        this.dateRangeValue = val;

        this.getAddrList();
      }
    },
    handleCancel() {
      this.showDatepicker = false;
    },
    selectChart(type) {
      this.chartType = type;
    },
    getList(data) {
      let params = {
        dateRangeValue: data.dateRangeValue,
      };

      if (params.dateRangeValue == null) {
        params["dateRange.from"] = data.startYmd ? moment(data.startYmd).format("YYYY-MM-DD") : null;
        params["dateRange.to"] = data.endYmd ? moment(data.endYmd).format("YYYY-MM-DD") : null;
      }

      this.chartDate = [];
      this.refData = [];
      this.seoul = [];
      this.busan = [];
      this.daegu = [];
      this.incheon = [];
      this.gwangju = [];
      this.daejeon = [];
      this.ulsan = [];
      this.sejongSi = [];
      this.gyeonggiDo = [];
      this.gangwonDo = [];
      this.chungcheongbukDo = [];
      this.chungcheongnamDo = [];
      this.jeollabukDo = [];
      this.jeollanamDo = [];
      this.gyeongsangbukDo = [];
      this.gyeongsangnamDo = [];
      this.jejuDo = [];

      this.preData = [];

      fetchAddrList(params).then((res) => {
        if (res.data.result == 0) {
          this.refBusanToTal = res.data.data.referenceBusanTotal;
          this.refChungbukToTal = res.data.data.referenceChungcheongbukDoTotal;
          this.refChungnamToTal = res.data.data.referenceChungcheongnamDoTotal;
          this.refDaeguToTal = res.data.data.referenceDaeguTotal;
          this.refDaejeonToTal = res.data.data.referenceDaejeonTotal;
          this.refGangwonToTal = res.data.data.referenceGangwonDoTotal;
          this.refGwangjuToTal = res.data.data.referenceGwangjuTotal;
          this.refGyeonggiToTal = res.data.data.referenceGyeonggiDoTotal;
          this.refGyeongbukToTal = res.data.data.referenceGyeongsangbukDoTotal;
          this.refGyeongnamToTal = res.data.data.referenceGyeongsangnamDoTotal;
          this.refIncheonToTal = res.data.data.referenceIncheonTotal;
          this.refJejuToTal = res.data.data.referenceJejuDoTotal;
          this.refJeonbukToTal = res.data.data.referenceJeollabukDoTotal;
          this.refJeonnamToTal = res.data.data.referenceJeollanamDoTotal;
          this.refSejongToTal = res.data.data.referenceSejongSiTotal;
          this.refUlsanToTal = res.data.data.referenceUlsanTotal;
          this.refSeoulToTal = res.data.data.referenceSeoulTotal;

          this.refLiveData = res.data.data.reference;

          this.preBusanToTal = res.data.data.previousBusanTotal;
          this.preChungbukToTal = res.data.data.previousChungcheongbukDoTotal;
          this.preChungnamToTal = res.data.data.previousChungcheongnamDoTotal;
          this.preDaeguToTal = res.data.data.previousDaeguTotal;
          this.preDaejeonToTal = res.data.data.previousDaejeonTotal;
          this.preGangwonToTal = res.data.data.previousGangwonDoTotal;
          this.preGwangjuToTal = res.data.data.previousGwangjuTotal;
          this.preGyeonggiToTal = res.data.data.previousGyeonggiDoTotal;
          this.preGyeongbukToTal = res.data.data.previousGyeongsangbukDoTotal;
          this.preGyeongnamToTal = res.data.data.previousGyeongsangnamDoTotal;
          this.preIncheonToTal = res.data.data.previousIncheonTotal;
          this.preJejuToTal = res.data.data.previousJejuDoTotal;
          this.preJeonbukToTal = res.data.data.previousJeollabukDoTotal;
          this.preJeonnamToTal = res.data.data.previousJeollanamDoTotal;
          this.preSejongToTal = res.data.data.previousSejongSiTotal;
          this.preUlsanToTal = res.data.data.previousUlsanTotal;
          this.preSeoulToTal = res.data.data.previousSeoulTotal;

          let preArr = [];
          preArr.push(this.preSeoulToTal);
          preArr.push(this.preBusanToTal);
          preArr.push(this.preDaeguToTal);
          preArr.push(this.preIncheonToTal);
          preArr.push(this.preGwangjuToTal);
          preArr.push(this.preDaejeonToTal);
          preArr.push(this.preUlsanToTal);
          preArr.push(this.preSejongToTal);
          preArr.push(this.preGyeonggiToTal);
          preArr.push(this.preGangwonToTal);
          preArr.push(this.preChungbukToTal);
          preArr.push(this.preChungnamToTal);
          preArr.push(this.preJeonbukToTal);
          preArr.push(this.preJeonnamToTal);
          preArr.push(this.preGyeongbukToTal);
          preArr.push(this.preGyeongnamToTal);
          preArr.push(this.preJejuToTal);

          this.preData = preArr;

          let refArr = [];
          refArr.push(this.refSeoulToTal);
          refArr.push(this.refBusanToTal);
          refArr.push(this.refDaeguToTal);
          refArr.push(this.refIncheonToTal);
          refArr.push(this.refGwangjuToTal);
          refArr.push(this.refDaejeonToTal);
          refArr.push(this.refUlsanToTal);
          refArr.push(this.refSejongToTal);
          refArr.push(this.refGyeonggiToTal);
          refArr.push(this.refGangwonToTal);
          refArr.push(this.refChungbukToTal);
          refArr.push(this.refChungnamToTal);
          refArr.push(this.refJeonbukToTal);
          refArr.push(this.refJeonnamToTal);
          refArr.push(this.refGyeongbukToTal);
          refArr.push(this.refGyeongnamToTal);
          refArr.push(this.refJejuToTal);

          this.refTotalData = refArr;

          this.startDate = this.refLiveData[0].date;
          this.endDate = this.refLiveData[this.refLiveData.length - 1].date;

          this.refLiveData.forEach((item) => {
            this.chartDate.push(item.date);
            this.seoul.push(item.seoul);
            this.busan.push(item.busan);
            this.daegu.push(item.daegu);
            this.incheon.push(item.incheon);
            this.gwangju.push(item.gwangju);
            this.daejeon.push(item.daejeon);
            this.ulsan.push(item.ulsan);
            this.sejongSi.push(item.sejongSi);
            this.gyeonggiDo.push(item.gyeonggiDo);
            this.gangwonDo.push(item.gangwonDo);
            this.chungcheongbukDo.push(item.chungcheongbukDo);
            this.chungcheongnamDo.push(item.chungcheongnamDo);
            this.jeollabukDo.push(item.jeollabukDo);
            this.jeollanamDo.push(item.jeollanamDo);
            this.gyeongsangbukDo.push(item.gyeongsangbukDo);
            this.gyeongsangnamDo.push(item.gyeongsangnamDo);
            this.jejuDo.push(item.jejuDo);
          });
          let arr = [];
          arr.push(this.seoul);
          arr.push(this.busan);
          arr.push(this.daegu);
          arr.push(this.incheon);
          arr.push(this.gwangju);
          arr.push(this.daejeon);
          arr.push(this.ulsan);
          arr.push(this.sejongSi);
          arr.push(this.gyeonggiDo);
          arr.push(this.gangwonDo);
          arr.push(this.chungcheongbukDo);
          arr.push(this.chungcheongnamDo);
          arr.push(this.jeollabukDo);
          arr.push(this.jeollanamDo);
          arr.push(this.gyeongsangbukDo);
          arr.push(this.gyeongsangnamDo);
          arr.push(this.jejuDo);
          for (let i = 0; i < arr.length; i++) {
            let obj = {
              name: this.region[i],
              data: arr[i],
            };
            this.refData.push(obj);
          }
        } else {
          alert(res.data.message);
        }
      });
    },
  },
};
</script>
