<template>
  <VueApexCharts
    ref="chart"
    type="pie"
    width="100%"
    height="339"
    :options="chartOptions"
    :series="series"
  >
  </VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chartData: {
      type: Array,
    },
    labels: {
      type: Array,
    },
  },
  data() {
    return {
      series: this.chartData,
      chartOptions: {
        chart: {
          type: "pie",
          toolbar: {
            show: false,
          },
        },
        labels: this.labels,

        dataLabels: {
          enabled: true,
        },
        crosshairs: {
          show: false,
        },
        legend: {
          position: "right",
          fontSize: "10px",
          markers: {
            fillColors: [
              "#0790FF",
              "#E34307",
              "#FF9D00",
              "#50B93C",
              "#47C1BF",
              "#CF0893",
              "#0753FF",
              "#FFBA00",
              "#6EBFFF",
              "#FF53AF",
              "#89E660",
              "#8D19FF",
              "#FF6200",
              "#01ED77",
              "#D69236",
              "#cc1414",
              "#21b5aa",
            ],
          },
        },

        fill: {
          colors: [
            "#0790FF",
            "#E34307",
            "#FF9D00",
            "#50B93C",
            "#47C1BF",
            "#CF0893",
            "#0753FF",
            "#FFBA00",
            "#6EBFFF",
            "#FF53AF",
            "#89E660",
            "#8D19FF",
            "#FF6200",
            "#01ED77",
            "#D69236",
            "#cc1414",
            "#21b5aa",
          ],
        },
      },
    };
  },
  mounted() {
    this.updateData();
  },
  watch: {
    chartData: function () {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.$refs.chart.updateOptions({
        series: this.chartData,
      });
    },
  },
};
</script>
